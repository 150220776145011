* {
  margin: 0;
}

header {
    height: max-content;
    background-color: #FFF2F2;
}

.homenav {
  background-image: linear-gradient(to right, #fff2f2 0%, #fff2f2 100%);
}

main {
  background-color: #FFF2F2;
  height: max-content;
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 3rem;
  /* Height of the footer */
  background: #fae7e7;
}

/*///////////////////////// Button Styles /////////////////////////*/

.button {
  border-radius: 1rem;
  text-decoration: none;
  transition-duration: 0.4s;
  font-size: 14px;
  font-family: "Metro-B";
}

.button.navy {
  background-color: #263159;
  color: #fae7e7;
  border: 2px solid #263159;
}

.button.red {
  background-color: #db4d4b;
  color: #fcc5c0;
  border: 2px solid #db4d4b;
}

.button.red.light {
  background-color: #db4d4b;
  color: #FFF2F2;
  border: 2px solid #db4d4b;
}


.button.navy:hover {
  background-color: transparent;
  color: #263159;
}

.button.red:hover {
  background-color: transparent;
  color: #db4d4b;
}

.button-navy.small {
  width: max-content;
}

.button-navy.large {
  width: 50%;
}

.button-lightpink {
  border-radius: 1rem;
  margin: 10px 10px;
  padding: 5px 15px;
  text-decoration: none;
  transition-duration: 0.4s;
  width: 150px;
  font-size: 16px;
  font-family: "Metro-M";
  background-color: #fcc5c0;
  color: #263159;
  border: 2px solid #fcc5c0;
}

.button-lightpink:hover {
  background-color: transparent;
  color: #fcc5c0;
}

.button-redit-liner {
  color: #db4d4b;
}

.button-redit-liner:hover {
  text-decoration-line: underline;
  text-decoration-thickness: 2px;
  text-decoration-color: #db4d4b;
}

.goback {
  background-image: linear-gradient(
    to right,
    #db4d4b,
    #db4d4b 50%,
    #263159 50%
  );
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 5px 0;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
}

.goback:before {
  content: '';
  background: #db4d4b;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s ease-in-out;
}

.goback:hover {
 background-position: 0;
}

.goback:hover::before {
  width:100%;
}

/*/////////////////////////////////////////////////////////////////*/

/*////////////////////////// Text Styles //////////////////////////*/

.text-48px {
  font-size: 3rem;
  /* 48px */
  line-height: 1;
  font-family: "Metro-B";
}

.text-45px {
  font-size: 2.8rem;
  /* 48px */
  line-height: 1;
  font-family: "Metro-B";
}

.text-40px {
  font-size: 2.5rem;
  /* 48px */
  line-height: 1;
  font-family: "Metro-B";
}

.text-36px {
  font-size: 2.25rem;
  /* 36px */
  line-height: 2.5rem;
  /* 40px */
  font-family: "Metro-B";
}

.text-30px {
  font-size: 1.875rem;
  /* 30px */
  line-height: 2.25rem;
  /* 36px */
  font-family: "Metro-B";
}

.text-24px {
  font-size: 1.5rem;
  /* 24px */
  line-height: 2rem;
  /* 32px */
  font-family: "Metro-M";
}

.text-24px.bold {
  font-family: "Metro-B";
}

.text-20px {
  font-size: 1.25rem;
  /* 20px */
  line-height: 1.75rem;
  /* 28px */
  font-family: "Metro-M";
}

.text-20px.bold {
  font-family: "Metro-B";
}

.text-18px {
  font-size: 1.125rem;
  /* 18px */
  line-height: 1.75rem;
  /* 28px */
  font-family: "Metro-M";
}

.text-18px.bold {
  font-family: "Metro-B";
}

.text-16px {
  font-size: 1rem;
  /* 16px */
  line-height: 1.5rem;
  /* 24px */
  font-family: "Metro-R";
}

.text-16px.pink {
  color: #fcc5c0;
}

.text-16px.bold {
  font-family: "Metro-B";
}

.text-14px {
  font-size: 0.875rem;
  /* 14px */
  line-height: 1.25rem;
  /* 20px */
  font-family: "Metro-R";
}

.text-14px.bold {
  font-family: "Metro-B";
}

.text-12px {
  font-size: 0.75rem;
  /* 12px */
  line-height: 1rem;
  /* 16px */
  font-family: "Metro-R";
}

.text-12px.bold {
  font-family: "Metro-B";
}

.text-12px a {
  color: #fcc5c0;
}

/*/////////////////////////////////////////////////////////////////*/

/*////////////////////////// images size //////////////////////////*/

.images-48px {
  width: 48px;
  height: 48px;
}

.images-30px {
    width: 30px;
    height: 30px;
}

.images-25px {
  width: 25px;
  height: 25px;
}

.images-20px {
  width: 20px;
  height: 20px;
}

.images-18px {
    width: 18px;
    height: 18px;
}

.images-16px {
  width: 16px;
  height: 16px;
}

.images-14px {
  width: 14px;
  height: 14px;
}


/*/////////////////////////////////////////////////////////////////*/

.lable-container {
  display: block;
  margin: 0rem 0.6rem;
}

.checkbox {
  display: flex;
  text-align: center;
  border: none;
  border-radius: 1rem;
  color: #263159;
  padding: 10px 30px;
  margin: 0.6rem 0.6rem;
  height: auto;
  width: max-content;
  background-color: #d9d9d9;
}

.container {
  align-items: center;
  text-align: center;
}

.container.new {
  width: 50%;
}

.input-container {
  display: block;
  justify-content: center;
  align-items: center;
}

.input {
  display: block;
  border: none;
  appearance: none;
  border-radius: 0rem;
  color: #263159;
  cursor: pointer;
  width: 100%;
  border-bottom: 1px solid #1e1e1e;
  background-color: transparent;
}

.input:focus {
  outline: none;
}

.input::placeholder {
  color: #263159;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis-des {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  height: 25px;
}

.overflow {
  /* background-color: #fed9ff; */
  width: 90%;
  max-height: 20rem;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
  justify-content: center;
  padding: 20px;
}

.line {
  border-top-width: 0rem;
  border-bottom-width: 0.1rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.line-vertical {
  border-right-width: 0.1rem;
  border-left-width: 0rem;
  border-color: #1e1e1e;
}

.line.new {
  margin-left: auto;
  margin-right: auto;
  border-color: #1e1e1e;
}

.line-horizon {
    border-top-width: 0rem;
    border-bottom-width: 1px;
    border-color: #1E1E1E;
}

/*/////////////////////////////////////////////////////////////////*/

/*////////////////////////// images size //////////////////////////*/

/* Dropdown Button */
.dropbtn {
    background-color: #3498DB;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }
  
  /* Dropdown button on hover & focus */
  .dropbtn:hover, .dropbtn:focus {
    background-color: #2980B9;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: #ddd;}
  
  /* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
  .show {display:block;}



/* Result Table */
.dataTables_wrapper {
  width: 100%;
  margin: 0 auto;
  font-size: 16px;
  font-family: 'Metro-B';
  color: #263159;
}

.dataTables_length select {
    width: 120px !important;
  }
  
.dataTables_filter {
  margin-left: 10px;
  margin-bottom: 5px;
}

table.dataTable {
  width: 100%;
  margin: 0 auto;
  clear: both;
  border-collapse: collapse;
  border-spacing: 0;
}

table.dataTable thead th {
  padding: 10px 15px;
  background-color: #FCC5C0;
  text-align: left;
}

table.dataTable tbody td {
  padding: 10px 15px;
  border-bottom: 1px solid #000;
  text-align: left;
}

table.dataTable tbody tr:nth-child(odd) {
  background-color: #FFF2F2;
}

table.dataTable tbody tr:hover {
  background-color: #FFF2F2;
}

/* slide show */
