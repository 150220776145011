  .bg-FAE7E7{
    background: #FAE7E7;
  }
  .bg-FCC5C0{
    background: #FCC5C0;
  }
  .bg-263159{
    background: #263159;
  }
  .bg-E22637{
    background: #E22637;
  }
  .color-FAE7E7{
    color: #FAE7E7;
  }
  .color-263159{
    color: #263159;
  }
  .color-E22637{
    color: #E22637;
  }

  .center {
    margin: auto;
    width: 100%;
  }
  .vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .shadow {
    box-shadow: 0 0 15px 0.01px rgb(165, 165, 171);
  }

  /* Create two equal columns that sits next to each other */
  .row {
    display: flex;
  }
  .column {
    flex: 50%;
    padding: 5%;
  }
  
  .forms .form-content .title{
    position: relative;
    font-size: 24px;
    font-weight: 500;
    color: #333;
  }
  .forms .form-content .title:before{
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 25px;
    background: #E22637;
  }
  .forms .form-content .input-boxes{
    margin-top: 30px;
  }
  
  .forms .form-content .input-box{
    display: flex;
    align-items: center;
    height: 50px;
    width: 100%;
    margin: 10px 0;
    position: relative;
  }
  .form-content .input-box input{
    height: 100%;
    width: 100%;
    outline: none;
    border: none;
    padding: 0 30px;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 2px solid rgba(0,0,0,0.2);
    transition: all 0.3s ease;
  }
  .form-content .input-box input:focus,
  .form-content .input-box input:valid{
    border-color: #263159;
  }
  .forms .form-content .text a:hover{
    text-decoration: underline;
  }
  .forms .form-content .button{
    color: #fff;
    margin-top: 40px;
  }
  .forms .form-content .button input{
    color: #fff;
    background: #263159;
    border-radius: 6px;
    padding: 0;
    cursor: pointer;
    transition: all 0.4s ease;
  }
  .forms .form-content .button input:hover{
    background: #263159;
  }
  .forms .form-content label{
    color: #263159;
    cursor: pointer;
  }
  .forms .form-content label:hover{
    text-decoration: underline;
  }
  .forms .form-content .sign-up-text{
    text-align: center;
    margin-top: 25px;
  }