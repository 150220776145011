@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Metro-B';
    src: url(fonts/Metropolis-Bold.otf);
}

@font-face {
    font-family: 'Metro-M';
    src: url(fonts/Metropolis-Medium.otf);
}

@font-face {
    font-family: 'Metro-R';
    src: url(fonts/Metropolis-Regular.otf);
}

body {
    margin: 0;
    background-color: #FFF2F2;
    font-family: 'Metro-R';
}

html {
    font-size: calc(60% + 0.8vmin);
}