.navbar {
	/* display: grid;
    grid-template-columns: 1fr 1fr; */
	justify-content: center;
	width: 100%;
	text-align: left;
	padding-left: 3rem;
	padding-right: 3rem;
}


.navbar-container {
	display: flex;
	align-items: center;
	padding: 1rem 1.5rem 1rem 1.5rem;
}

.navbar-container.nav {
	padding: 1rem 3rem 1rem 3rem;
}

.navbar-container.left {
	background-color: #FFF2F2;
}

.navbar-container.left.right-side {
	justify-content: flex-end;
}

.navbar-container.right {
	background-color: #FCC5C0;
	justify-content: flex-end;
}

.navbar-container.right.new {
	justify-content: flex-end;

}

.dropdown:focus-within .dropdown-menu {
	opacity:1;
	transform: translate(0) scale(1);
	visibility: visible;
  }