.list-container {
    display: block;
    align-items: center;
    text-align: center;
    border-radius: 0.5rem;
}

.add-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}



.list-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    /* border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem; */
}

.enter-container {
    display: flex;
    align-items: center;
    text-align: center;
    padding-right: 0.5rem;
}

.show-container {
    display: grid;
    gap: 1rem;
    margin: auto;
    justify-items: center;
    align-items: center;
}

